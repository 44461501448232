
import { defineComponent, onMounted, ref, computed } from 'vue'
import axios from '@/http'
interface ICurrency {
	id: number
	previousValue: number
	actualValue: number
	futureValue?: number
	risk: number
}

export default defineComponent({
	setup() {
		const currency = ref<ICurrency>()
		const currencyFuture = ref<string>('')
		const savingState = ref<number>(0)
		const applyState = ref<number>(0)

		onMounted(async () => {
			await getCurrency()
		})

		const canSave = computed(() => {
			if (savingState.value === 1 || currencyFuture.value === '') return false
			if (isNaN(Number(currency.value?.futureValue))) {
				return true
			}
			return Number(currencyFuture.value) !== Number(currency.value?.futureValue)
		})

		async function getCurrency() {
			const currencies = await axios.get('/internal/get-all-currency')
			if (currencies?.data?.length) {
				currency.value = currencies.data[0]
				if (currency.value?.futureValue) {
					currencyFuture.value = currency.value.futureValue?.toString()
				}
			}
		}

		async function save() {
			try {
				savingState.value = 1
				if (!currency.value) return
				await axios.post('/internal/update-currency-future', {
					id: currency.value.id,
					futureValue: currencyFuture.value || null
				})
				await getCurrency()
				savingState.value = 2
			} catch (e) {
				console.error('save currency page', e)
				savingState.value = 3
			}
		}

		async function apply() {
			try {
				applyState.value = 1
				const result = await axios.get('/internal/change-currency-actual')
				if (!result.data) {
					throw new Error(`can't change change currency `)
				}
				await getCurrency()
				applyState.value = 2
			} catch (e) {
				console.error('apply currency page', e)
				applyState.value = 3
			}
		}

		function validateDecimal() {
			if (!Number(currencyFuture.value)) currencyFuture.value = currencyFuture.value.slice(0, -1)
			const idx = currencyFuture.value.indexOf('.')
			if (idx === -1) {
				if (currencyFuture.value.length > 3) {
					currencyFuture.value = currencyFuture.value.slice(0, -1)
				}
				return
			}
			if (idx + 3 < currencyFuture.value.length) currencyFuture.value = currencyFuture.value.slice(0, -1)
		}

		return {
			canSave,
			currency,
			savingState,
			applyState,
			currencyFuture,

			validateDecimal,
			apply,
			save
		}
	}
})
